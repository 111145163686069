/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './assets/css/animations.scss';
@import '~swiper/swiper.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/*Login global*/
@import './app/css-global/login.scss';
//@import "./app/css-global/login.scss";

@font-face {
  font-family: digital;
  src: url('./assets/fts/DS-DIGI.TTF');
  font-weight: 200;
}

@font-face {
  font-family: helvetica;
  src: url('./assets/fts/HelveticaNeue.ttf');
  font-weight: 200;
}

@font-face {
  font-family: helvetica-bold;
  src: url('./assets/fts/helveticaneue/Helvetica\ Neu\ Bold.ttf');
  font-weight: 200;
}

@font-face {
  font-family: safe;
  src: url('./assets/fts/safe.ttf');
  font-weight: 200;
}
header.page-header {
  
  margin-top: 45px !important;}

  .customModal  {

    --width: auto;
    --height: auto;
    --min-width: 920px;
    --max-width: 920px;
    --min-height: auto;
    --max-height: 90%;
    --overflow: auto;
    --border-radius: 4px;
    --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  
    .modal-wrapper {
      margin: 12px;
    }
  }


.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  margin-top: 15px;
}





table.dataTable {
  margin-top: 10px;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='gray'/%3e%3c/svg%3e");

  &:focus,
  &:active {
    box-shadow: none;
    border: none;
    outline: none;
  }
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2335f3ba'/%3e%3c/svg%3e");
  background-color: #99f8dc;
  border: none;
}

.popover-class {
  .popover-wrapper {
    .popover-content {
      top: 25px !important;
      left: unset !important;
      right: 5px !important;
    }

    .popover-arrow:after {
      display: none !important;
    }
  }

  .popover-wrapper:after {
    content: '';
    position: absolute;
    top: 7px;
    left: unset;
    right: 12px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 10px transparent;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 10px #fff;
  }
}

.ion-page {
  // background-color: #757575;
  --ion-background-color: transparent !important;
}

ion-content {
  padding-top: var(–ion-safe-area-top, 0);
  position: unset;
}

body.swal2-height-auto {
  height: inherit;
}
.alerta-vista{
  .swal2-actions {
    button {
      background-color: #35f3ba;
      color:white
    }
  }
}

.alerta-vista-warn {
  .swal2-actions {
    button {
      background-color:#35f3ba;
      color: #5e5e5e;
    }
  }

  .swal2-content {
    div {
      .mensaje {
        p {
          font-weight: 600;
        }
      }

      input {
        outline: none;
        padding: 5px;
        margin: 0;
        width: 100%;
        border: 1px solid #cacaca;
        border-radius: 4px;
        background-color: #fff;
      }
    }
  }
}

.alerta-vista-error {
  .swal2-actions {
    button {
      background-color: #35f3ba;
      color: #fff;
    }
  }

  .swal2-content {
    div {
      .mensaje {
        p {
          font-weight: 600;
        }
      }
    }
  }
}


// table {
//   border: 1px solid #ccc;
//   border-collapse: collapse;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   table-layout: fixed;
// }

// table caption {
//   font-size: 1.5em;
//   margin: 0.5em 0 0.75em;
// }

// table tr {
//   background-color: #f8f8f8;
//   border: 1px solid #ddd;
//   padding: 0.35em;
// }

// table th,
// table td {
//   padding: 0.625em;
//   text-align: center;
// }

// table th {
//   font-size: 0.85em;
//   letter-spacing: 0.1em;
//   text-transform: uppercase;
// }

// @media screen and (max-width: 600px) {
//   table {
//     border: 0;
//   }

//   table caption {
//     font-size: 1.3em;
//   }

//   table thead {
//     border: none;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }

//   table tr {
//     border-bottom: 3px solid #ddd;
//     display: block;
//     margin-bottom: 0.625em;
//   }

//   table td {
//     border-bottom: 1px solid #ddd;
//     display: block;
//     font-size: 0.8em;
//     text-align: right;
//   }

//   table td::before {
//     /*
//       * aria-label has no advantage, it won't be read inside a table
//       content: attr(aria-label);
//       */
//     content: attr(data-label);
//     float: left;
//     //font-weight: bold;
//     text-transform: uppercase;
//   }

//   table td:last-child {
//     border-bottom: 0;
//   }
// }


.mat-drawer-container {
  height: -webkit-fill-available;
}

.mat-drawer-inner-container {
  width: 230px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3498db !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #3498db !important;
}

.mat-dialog-container {
  max-height: 500px !important;
}

.mat-drawer-content {
  margin-left: 0px !important;
}

//Myself skeleton
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.trivia {
  background-color: rgba(25, 25, 25, 0.88) !important;
  border: 4px solid #3d9eff !important;
  box-shadow: 1px 1px 124px #3d9eff;
  .swal2-content {
    div {
      .mensaje {
        p {
          margin: 0;
          color: #fff;
          font-size: 40px;
          animation: tada 1s infinite;
          line-height: 45px;
        }
      }

      .descripcion {
        p {
          margin: 0;
          color: #fff;
          font-size: 20px;
          margin-top: 20px;
          animation: pulse 1s infinite;
        }
      }
    }
  }
}

.mat-drawer-container {
  height: 100%;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3498db !important;
}

body {
  font-family: helvetica-bold;
}

.fab-register {
  bottom: 10px !important;
  right: 10px !important;
  background-color: #29b9e1 !important;
  padding: 12px !important;
  span {
    font-size: 25px !important;
  }
}

//**//Action sheets
.action-sheets-olam {
  --background: #ffffff;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  --button-background: linear-gradient(
      0deg,
      rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.08),
      rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.08) 50%,
      transparent 50%
    )
    bottom/100% 1px no-repeat transparent;
  --button-background-activated: var(--ion-text-color, #000);
  --button-background-activated-opacity: 0.08;
  --button-background-hover: currentColor;
  --button-background-hover-opacity: 0.04;
  --button-background-focused: currentColor;
  --button-background-focused-opacity: 0.12;
  --button-background-selected: var(
    --ion-color-step-150,
    var(--ion-background-color, #fff)
  );
  --button-background-selected-opacity: 1;
  --button-color: #29b9e1;
  --color: var(--ion-color-step-400, #999999);
  text-align: center !important;
  .action-sheet-wrapper {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    margin-top: var(--ion-safe-area-top, 0);
    margin-bottom: var(--ion-safe-area-bottom, 0);
    .action-sheet-container {
      padding-left: unset;
      padding-right: unset;
      -webkit-padding-start: 8px;
      padding-inline-start: 8px;
      -webkit-padding-end: 8px;
      padding-inline-end: 8px;
      .action-sheet-group {
        border-radius: 13px;
        margin-bottom: 8px;
        -ms-flex-negative: 2;
        flex-shrink: 2;
        overscroll-behavior-y: contain;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        pointer-events: all;
        background: var(--background);

        .action-sheet-title {
          text-align: center;
          font-size: 22px;
        }

        .action-sheet-destructive {
          color: #ec0000 !important;
          span {
            ion-icon {
              color: #ec0000 !important;
              margin-inline-end: 0.3em;

              .icon-inner {
                color: #ec0000 !important;
              }
            }
          }
        }

        .action-sheet-button {
          padding-left: unset;
          padding-right: unset;
          -webkit-padding-start: 18px;
          padding-inline-start: 18px;
          -webkit-padding-end: 18px;
          padding-inline-end: 18px;
          border-bottom: 1px solid #f0f0f0;
          padding-top: 18px;
          padding-bottom: 18px;
          height: 56px;
          font-size: 20px;
          contain: strict;

          color: #29b9e1;
          span {
            justify-content: center;
            display: flex;
            position: relative;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            pointer-events: none;
            width: 100%;
            height: 100%;
            z-index: 1;
            ion-icon {
              color: #29b9e1;
              margin-inline-end: 0.3em;
              font-size: 28px;
              pointer-events: none;
              .icon-inner {
                color: #29b9e1;
              }
            }
          }
        }

        .action-sheet-button::after {
          position: initial;
        }
      }
    }
  }
}

.mt-lg {
  margin-top: 3rem !important;
}
